@import 'styles/includes';

.MyPagesHeader {
    $root: &;

    background-color: $colorWhite;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 8000;
    transition: ease-out 0.4s transform;

    &--CustomerService {
        top: 4.8rem;
    }

    &--Hidden {
        transform: translateY(-100%);
    }

    &__Top {
        display: block;
        border-bottom: 1px solid $colorLichen;
        font-size: 1.4rem;
        color: $colorContrast;
        background-color: $colorWhite;
        position: relative;
        z-index: 999;
    }

    &__Bottom {
        background-color: $colorWhite;
        position: relative;
        z-index: 999;
    }

    &__Inner {
        @extend %nav-container;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__Back {
        padding: 1.6rem 0;
        display: flex;
        gap: 1.5rem;
    }

    &__Logo {
        margin: 1.2rem 0 1.2rem 0;
        width: 100%;
        min-width: 152px;
        max-width: 152px;
        height: 45px;
        display: block;
        background-image: url('/img/logo-jamtkraft.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__Icons {
        display: flex;
        justify-content: center;
        align-items: baseline;
        position: relative;
        gap: 2rem;

        @include media(ml) {
            display: none;
        }
    }

    &__Logout {
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 0.7rem;
        color: $colorBlue;
    }

    &__ProfileText {
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 700;
        color: $colorBlue;
        display: inline-block;
    }

    &__Toggle {
        padding: 0;
        background-color: transparent;
        border: 0;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        align-items: center;
        position: relative;
        top: -0.2rem;
    }

    &__Hamburger {
        position: relative;
        display: block;
        width: 2.5rem;
        height: 1.8rem;
    }

    &__Line {
        width: 2.5rem;
        height: 0.2rem;
        background-color: $colorBlue;
        border-radius: 1rem;
        display: block;
        position: absolute;
        left: 0;
        transition:
            background-color $transition,
            transform $transition,
            opacity $transition;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 0.8rem;
            background-color: $colorYellow;
        }

        &:nth-child(3) {
            bottom: 0;
        }

        #{$root}__Toggle--Active & {
            &:nth-child(1) {
                transform: translateY(0.6rem) rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: translateY(-1rem) rotate(-45deg);
                background-color: $colorYellow;
            }
        }
    }

    &__ToggleText {
        width: 3.3rem;
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 700;
        color: $colorBlue;
    }

    &__Button {
        display: none;

        @include media(ml) {
            display: block;
        }
    }

    &__Navigation {
        display: none;

        @include media(ml) {
            display: block;
        }
    }

    &__NavigationList {
        @include media(ml) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 3rem;
        }
    }

    &__NavigationLink {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 3.3rem;
        padding-bottom: 3.3rem;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 600;
        color: $colorBlue;
        position: relative;
        background-color: transparent;
        display: inline-block;
        border: 0;
        border-radius: 0;

        &::after {
            content: '';
            height: 4px;
            width: 0;
            background-color: transparent;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: $transition;
        }

        &:focus-visible,
        &:hover {
            &::after {
                background-color: $colorYellow;
                width: 100%;
            }
        }

        &--Invoices {
            position: relative;

            &::before {
                content: '';
                width: 0.8rem;
                height: 0.8rem;
                background-color: $colorError;
                border-radius: 100%;
                position: absolute;
                top: 2.6rem;
                right: -0.8rem;
            }
        }

        &--Active {
            &::after {
                width: 100%;
                background-color: $colorYellow;
            }
        }
    }

    &__MobileNavigation {
        padding: 14rem 2rem 7.4rem 2rem;
        background-color: $colorWhite;
        position: fixed;
        inset: 0;
        z-index: 9;
        transform: translateY(-100vh);
        transition: transform $transitionDelaySlow $ease;

        &--Open {
            transform: translateY(0);
        }

        @include media(ml) {
            display: none;
        }
    }

    &__MobileNavigationInner {
        position: relative;
        height: 100%;
        overflow: scroll;
        visibility: hidden;
        transition: $transitionDelaySlow visibility;

        #{$root}__MobileNavigation--Open & {
            visibility: visible;
        }
    }

    &__MobileNavigationList {
        margin: 3.2rem 0 0 0;
    }

    &__MobileNavigationItem {
        margin-bottom: 2rem;
    }

    &__MobileNavigationLink {
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: $fontBlack;
        font-weight: 900;
        font-size: 2.2rem;
        line-height: 1.5;
        letter-spacing: normal;
        color: $colorBlue;
        border: 0;
        background-color: transparent;
    }

    &__MobileNavigationBottom {
        padding: 1rem 0 calc(1.5rem + env(safe-area-inset-bottom)) 0;
        background-color: $colorWhite;
        border-top: 1px solid $colorYellow;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        position: fixed;
        bottom: 0;
        left: 2rem;
        right: 2rem;
    }

    &__BottomLink {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__LanguageSelect {
        position: relative;
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: $colorBlue;
    }

    &__LanguageButton {
        margin: 0;
        padding: 10px;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.6rem;
        color: $colorBlue;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        border-top: 0;
        background-color: $colorWhite;

        #{$root}__LanguageSelect--IsExpanded & {
            border-top: 0;
            border-right: 1px solid $colorBlue;
            border-bottom: 1px solid $colorBlue;
            border-left: 1px solid $colorBlue;
            border-radius: 0 0 8px 8px;
        }
    }

    &__LanguageList {
        display: none;
        padding: 10px 10px 0 10px;
        width: 100%;
        position: absolute;
        bottom: 100%;
        border-top: 1px solid $colorBlue;
        border-right: 1px solid $colorBlue;
        border-bottom: 0;
        border-left: 1px solid $colorBlue;
        border-radius: 8px 8px 0 0;
        background-color: $colorWhite;

        #{$root}__LanguageSelect--IsExpanded & {
            display: block;
        }
    }

    &__LanguageListItem {
        font-weight: 600;
    }

    &__LanguageLink {
        font-size: 1.6rem;
        padding: 10px 0;
        display: inline-block;
    }
}
